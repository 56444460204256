@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

html {
    overflow-x: hidden !important;
}

body, * {
    font-family: 'Roboto', sans-serif;
    
}
body {
    background-color: #0A101E !important;
}