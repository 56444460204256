.Comingsoon_main {
    color: #fff;
    padding: 120px 0px 200px 0px;
    text-align: center;
    position: relative;
    h2 {
        margin-bottom: 20px;
        letter-spacing: 5px;
        font-weight: 600;
        font-size: 60px;
        line-height: 70px;
        text-align: center;
        color: #ffffff;
    }
    p {
        font-weight: 400;
        font-size: 32px;
        line-height: 150%;
        color: rgba(255, 255, 255, 0.84);
        width: 70%;
        margin: 16px auto 40px;
    }
    .bgTopbanner-Image {
        position: absolute;
        top: 100px;
        width: 100%;
        img {
            width: 100%;
        }
    }
}


@media (max-width:990px) { 
    .Comingsoon_main {
        padding: 60px 0px 100px 0px;
        h2 {
            font-size: 36px;
            line-height: 40px;
            letter-spacing: 0px;
        }
        p {
            font-size: 20px;
            width: 100%;
        }
        .bgTopbanner-Image {
            display: none;
        }
    }
 }