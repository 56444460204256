.Contactus_Main {
    margin-bottom: 150px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        bottom: -150px;
        width: 100%;
        height: 70%;
        background-color: #fff;
    }
}

.Contactform {
    z-index: 999;
    position: relative;
    background-color: #050911;
    padding: 50px;
    border-radius: 50px;
    margin: 60px 0px;
    margin-top: 40px !important;
    form {
        padding-left: 30px;
        label {
            color: rgb(255 255 255 / 51%);
            margin-bottom: 2px;
            font-size: 16px;
        }
        .form-control {
            min-height: 50px;
            border-radius: 4px;
            background-color: #0a101e;
            border: 0px none;
        }
    }
    .image_contatcuspage {
        h3 {
            font-size: 40px;
            color: #fff;
        }
        p {
            color: rgb(255 255 255 / 84%);
            font-size: 18px;
        }
    }
    .contact_info {
        border-top: 1px solid #0b1224;
        padding-top: 30px;
        margin-top: 40px;
        color: #fff;
        p {
            span {
                margin-right: 10px;
                font-size: 18px;
                color: #1467d9;
            }
        }
    }
    .Contact_social {
        border-top: 1px solid #0b1224;
        padding-top: 30px;
        margin-top: 40px;
        color: #fff;
        h3 {
            font-size: 24px;
            line-height: 38px;
        }
        ul {
            margin: 0px;
            padding: 0px;
            list-style: none;
            margin-top: 20px;
            li {
                display: inline-block;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: rgba(255, 255, 255, 0.84);
                a {
                    width: 48px;
                    height: 48px;
                    display: inline-block;
                    background-color: rgba(23, 121, 255, 0.84);
                    color: #fff;
                    font-size: 22px;
                    margin: 0px 8px;
                    border-radius: 50px;
                    line-height: 46px;
                    padding: 0px;
                    text-align: center;
                }
            }
        }
    }
    .formerror {
        color: #dd0606;
    }
}
