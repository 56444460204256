.lofoslider_images {
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
}
.Logosynchrony_main {
    .react-multi-carousel-list {
        vertical-align: top;
        display: flex;
        align-items: center;
        z-index: 99;
        position: relative;
        &:before {
            content: "";
            background-image: url(../../Static/LogoLeftside.png);
            width: 224px;
            height: 228px;
            left: -60px;
            position: absolute;
            z-index: 999;
        }
        &:after {
            content: "";
            background-image: url(../../Static/LogoRigthside.png);
            width: 224px;
            height: 228px;
            right: -60px;
            position: absolute;
            z-index: 999;
        }
        button.react-multiple-carousel__arrow {
            display: none;
        }
    }
    
}


@media (max-width:464px) {
    .Logosynchrony {
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }
    .react-multi-carousel-list {
        &:before {
            display: none;
        }
        &:after {
            display: none;
        }
    }
 }