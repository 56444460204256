
.footer_mian {
    background-color: #050911;
    color: #fff;
    padding: 40px 0px 40px 0px;
    position: relative;
    margin-top: 0px;
    text-align: center;
    &:before {
        content: "";
        background-image: url(../../Static/Footershaps.svg);
        top: -260px;
        position: absolute;
        width: 100%;
        background-size: cover;
        height: 260px;
        z-index: 0;
        left: 0px;
        // background-color: #0a101e;
        background-position: top;
    }
    .Synchronyfooter_logo {
        z-index: 999999;
        position: relative;
        margin-top: 20px;
    }
    ul {
        padding: 0;
        margin: 0;
        list-style: none;
        li {
            display: inline-block;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: rgba(255, 255, 255, 0.84);
        }
    }
    .Social_footer {
        margin: 40px 0px 40px 0px;
        ul {
            li {
                a {
                    width: 48px;
                    height: 48px;
                    display: inline-block;
                    background-color: rgb(23 121 255 / 84%);
                    color: #fff;
                    font-size: 22px;
                    margin: 0px 8px;
                    border-radius: 50px;
                    line-height: 46px;
                    padding: 0px;
                    &:hover {
                        background-color: rgb(23 121 255 / 100%);
                    }
                }
            }
        }
    }
    .footer_links {
        margin: 20px 0px 40px 0px;
        ul {
            li {
                a {
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    color: rgba(255, 255, 255, 0.84);
                    text-decoration: none;
                    margin: 0px 20px;
                    &:hover {
                        color: rgba(255, 255, 255, 1);
                    }
                }
            }
        }
    }
    p.Copyright_section {
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(255, 255, 255, 0.54);
    }
}

@media (max-width:992px) {
    .footer_mian {
        padding: 60px 0px 40px 0px;
        margin-top: 0px;
        &:before {
            display: none;
        }
        .footer_links {
            margin: 10px 0px 40px 0px;
            ul {
                li {
                    display: inline-block;
                    width: 100%;
                    padding: 10px 0px;
                }
            }
        }
    }
}
